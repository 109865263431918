<template>
<v-main class="ma-0">
  <slot/>
  <Footer v-if="!loader" />
</v-main>
</template>

<script>
import Footer from "@/components/Widget/Footer.vue";
import {createNamespacedHelpers} from "vuex";
export default {
  components:{Footer},
  name: "PagesLayout",
  computed: {
    ...createNamespacedHelpers("Loader").mapGetters([
      "loader"
    ]),
  }
}
</script>

<style scoped>

</style>